import {Col, Container, Form, Row} from "react-bootstrap";
import {useState} from "react";

import {ethers} from 'ethers'
import ABI from '../contract/JTC.json';
import {jTC_addr} from '../contract/addresses'
import Web3Modal from 'web3modal'


function RewardToken() {

    const [formData, setFormData] = useState({walletAddress: '', amount: 0});

    const loadProvider = async () => {
        try {
            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();
            const provider = new ethers.providers.Web3Provider(connection);
            return provider.getSigner();
        } catch (e) {
        }
    };


    const transfer = async (address, amount) => {
        try {
            
            let signer = await loadProvider()
            let OLU = new ethers.Contract(jTC_addr, ABI, signer)
            let decimals = await OLU.decimals()
            let value = ethers.utils.parseUnits((Number(amount)).toString(), decimals)
            let tx = await OLU.transfer(address, value)
            await tx.wait()

        } catch (e) {
            console.log('Error occurred while transferring', e);
        }
    }

    const onChangeHandler = (e) => {
        const {id, value} = e.target;
        setFormData(prevState => ({...prevState, [id]: value}));
    };

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        try {
            const {walletAddress, amount} = formData;
            await transfer(walletAddress, amount);
            setFormData({walletAddress: '', amount: 0});
        } catch (e) {
            console.log('Error occurred while transferring', e);
        }

    }

    return (
        <>
            <Container fluid className="main-height">
                <Row>
                    <Col lg={12} md={12}>
                        <div className="custom-chart-margin">
                            {/* <FinancialChartStockIndexChart/> */}
                            <h3 className="section-title ">Reward Tokens</h3>
                            <div className="analytics ">
                                <div className="dashboard-box  reward-token-box ">
                                    <h3 className="section-title  mb-4">Reward Tokens</h3>

                                    <Form onSubmit={onSubmitHandler}>
                                        <Form.Group
                                            className="mb-3"
                                            controlId="walletAddress"
                                        >
                                            <Form.Control value={formData.walletAddress}
                                                          type="text" placeholder="Wallet Address"
                                                          onChange={onChangeHandler} required
                                            />
                                        </Form.Group>
                                        <Form.Group
                                            className="mb-3"
                                            controlId="amount"
                                        >
                                            <Form.Control value={formData.amount} type="number"
                                                          placeholder="Amount" onChange={onChangeHandler}
                                                          required
                                            />
                                        </Form.Group>
                                        <button className="head-btn rounded" type='submit'>Submit</button>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default RewardToken;
import axios from "axios";

const createBackendServer = baseURL => {
    const api = axios.create({
        baseURL,
        headers: {'Accept': 'application/json'},
        timeout: 60 * 1000
    });

    const headers = {
        'Content-Type': 'multipart/form-data',
    }

    /*==========    GET REQUESTS    ==========*/
    const getMonthData = (month) => api.get(`/users/${month}`);
    const getBlogs = () => api.get('/blogs')

    /*==========    POST REQUESTS    ==========*/
    const createMonthData = (body) => api.post(`/users`, body);
    const addBlog = async (body) => api.post('/blogs/add', body, {headers})
    const transferContract = async (body) => api.post('', body);

    /*==========    DELETE REQUESTS    ==========*/
    const deleteBlog = async (id) => api.delete(`/blogs/delete/${id}`);

    /*==========    PUT REQUESTS    ==========*/
    const editBlog = async ({_id, ...restData}) => await api.put(`/blogs/update/${_id}`, restData, {headers})

    return {getMonthData, getBlogs, createMonthData, addBlog, deleteBlog, editBlog, transferContract}
};

const SERVER_URL = process.env.REACT_APP_API_BASE_URL;

const apis = createBackendServer(SERVER_URL);


export default apis;
import React, { useState, useEffect } from 'react'
import { Button, Col, Container, Form, Modal as CustomModal, Row } from "react-bootstrap";


const Modal = ({ show, handleClose, modalTitle, handleSubmit, data, setData ,loader,reqireState }) => {

  

    useEffect(() => {
        if(modalTitle === 'Create Blog') {
            setData({ image: null, title: '', description: '', _id: '' })
        }
    },[modalTitle]);

    const onChangeHandler = (e) => {
        const { name, value, files } = e.target;

        if (files) {
            setData(prevData => ({ ...prevData, [name]: files[0] }))
        } else {
            setData(prevData => ({ ...prevData, [name]: value }))
        }
    }


    return (
        <CustomModal show={show} onHide={handleClose}>
            <CustomModal.Header closeButton>
                <CustomModal.Title>{modalTitle}</CustomModal.Title>
            </CustomModal.Header>
            <Form onSubmit={(e)=>{
                e.preventDefault()
                handleSubmit(data)}}>
            <CustomModal.Body>

                <div className="dashboard-box  reward-token-box ">

                    
                        <Form.Group controlId="formFileSm" className="mb-3">
                            <Form.Label>Image</Form.Label>
                            <Form.Control type="file" size="sm" name="image"
                                onChange={onChangeHandler} 
                                required ={reqireState} />
                        </Form.Group>

                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                           
                        >
                            <Form.Label>Blog Title</Form.Label>
                            <Form.Control type="text" name='title' placeholder="Blog Title" value={data.title}
                                onChange={onChangeHandler} required />
                        </Form.Group>

                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                        >
                            <Form.Label>Blog Description</Form.Label>
                            <Form.Control as="textarea" rows={3} type="text" name='description' placeholder="Blog Description"
                                value={data.description} onChange={onChangeHandler}
                                required />
                        </Form.Group>

                </div>
            </CustomModal.Body>
            <CustomModal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>

                <Button className="head-btn" variant="secondary" type='submit' disabled={loader}>
                    Save Changes
                </Button>
            </CustomModal.Footer>
            </Form>
        </CustomModal>

    )
}

export default Modal;
import { Button, Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import { memo } from "react";
import { useState,useEffect } from "react";


import axios from "axios"

function Kyc() {

  const [kycs, setKycs] = useState([])
  const [show, setShow] = useState(false)
  const [NicShow, setshowNic] = useState(false)
  const [resShow, setresShow] = useState(false)

  const [loader, setLoader] = useState(false);

  const handleClose = () => setShow(false)
  const show_open = () => setShow(true)
  const handleNicClose = () => setshowNic(false)
  const showNic_open = () => setshowNic(true)
  const handleResShow = () => setresShow(false)
  const showRes_open = () => setresShow(true)


  const [modalData, setModalData] = useState({})

  const fetchData = async () => {
    try {
      setLoader(true);
      const data = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/kycs`)
      // console.log(data.data.data)
      setKycs(data.data.data)
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error.response)
    }
  }

  useEffect(() => {
    (async () => {
      await fetchData()
    })()
  }, [])

  const setDate = (createdAt) => {
    var d = new Date(createdAt)
    let date = d.toLocaleDateString()
    return date
  }

  const showNic = (kyc) => {
    let passport = kyc.passport.slice(0, 7) + "/" + kyc.passport.slice(8)
    let nic_front = kyc.nic_front.slice(0, 7) + "/" + kyc.nic_front.slice(8)
    let nic_back = kyc.nic_back.slice(0, 7) + "/" + kyc.nic_back.slice(8)

    // console.log("passport",passport)
    setModalData({...kyc, passport, nic_front, nic_back})
    showNic_open()
    // console.log(`${process.env.REACT_APP_API_BASE_URL}/${modalData.passport}`)
  }
  const showPassport = (kyc) => {
    let passport = kyc.passport.slice(0, 7) + "/" + kyc.passport.slice(8)
    // console.log(kyc)
    setModalData({...kyc, passport})
    show_open()
  }
  const showResidential = (kyc) => {

    setModalData(kyc)
    showRes_open()
  }

  const deleteKyc = async (id) => {
    try {
      const res = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/users/user_kyc/` + id)
      // console.log(res.data.message)
      await fetchData()
    } catch (error) {
      console.log(error.response)
    }
  }

  if(loader) return <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Spinner animation="border" variant="danger" />
  </div>

  return <>
    <Container fluid className="main-height">
      <Row>
        <Col lg={12} md={12}>
          <div className="custom-chart-margin">
            <h5 className="section-title">Kycs</h5>
            <div className="overflow-auto">
              <table className="table table-striped mt-3">
                <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Date of Birth</th>
                  <th scope="col">Resedential</th>
                  <th scope="col">CNIC</th>
                  <th scope="col">Passport</th>
                  <th scope="col">Action</th>


                  {/* <th scope="col">Show More</th> */}
                </tr>
                </thead>
                <tbody>
                {kycs != null && kycs.length > 0 && kycs.map((kyc, i) => {
                  return <tr>
                    <td>{i + 1}</td>
                    <td>{`${kyc.firstname} ${kyc.middlename} ${kyc.lastname}`}</td>
                    <td>{kyc.phone}</td>
                    <td>{kyc.dob && setDate(kyc.dob)} </td>
                    <td>
                      <button className="showbtn" onClick={() => showResidential(kyc)}>View</button>
                    </td>
                    <td>
                      <button className="showbtn" onClick={() => showNic(kyc)}>View</button>
                    </td>
                    <td>
                      <button className="showbtn" onClick={() => showPassport(kyc)}>View</button>
                    </td>
                    <td>
                      <button className="showbtn deletebtn" onClick={() => deleteKyc(kyc._id)}>Delete</button>
                    </td>
                    {/* <td> <button className="showbtn" onClick={()=>showData(kyc)}>Show</button></td> */}
                  </tr>
                })}
                </tbody>
              </table>
            </div>
          </div>
        </Col>
      </Row>

      <Modal
        show={show} onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Passport
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img style={{width: "100%", height: "auto"}}
               src={`${process.env.REACT_APP_API_BASE_URL}/${modalData.passport}`}/>

        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} style={{backgroundColor: "#0581df"}}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={NicShow} onHide={handleNicClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            NIC FRONT AND BACK
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img style={{width: "50%", height: "auto"}}
               src={`${process.env.REACT_APP_API_BASE_URL}/${modalData.nic_front}`}/>
          <img style={{width: "50%", height: "auto"}}
               src={`${process.env.REACT_APP_API_BASE_URL}/${modalData.nic_back}`}/>

        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleNicClose} style={{backgroundColor: "#0581df"}}>Close</Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={resShow} onHide={handleResShow}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Resedential Data
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{modalData.residential}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleResShow} style={{backgroundColor: "#0581df"}}>Close</Button>
        </Modal.Footer>
      </Modal>

    </Container>
  </>
}

export default memo(Kyc);